

































import { Mixin } from "@/core/mixins/mixin";
import { CommodityDtos } from "@/shims.decorator";
import { Row, Image, List, Empty, Loading } from "vant";
import { Component, Mixins, Prop } from "vue-property-decorator";
import mallProList from "@/components/mallProList.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [List.name]: List,
    [Empty.name]: Empty,
    [Loading.name]: Loading,
    mallProList,
  },
})
export default class MallResult extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean }) public recommend!: boolean;
  @Prop({ default: "", type: String }) public searchKeyword!: string;
  @Prop({ default: "暂无相关搜索结果", type: String })
  placeholder!: string;
  @Prop({ default: -1, type: Number }) public maxCount!: number; // 展示最大记录数，当设置了这个数时不再分页

  mallRecommendList: Array<good.CommodityDto> = [];
  loading = false;
  public List = {
    finished: false,
  };
  public Page = {
    current: 1,
    size: 20,
  };

  changeKeywords(value: string): void {
    this.searchKeyword = value;
  }

  refreshData(): void {
    this.mallRecommendList = [];
    this.getMallResult(true);
  }

  getMallResult(first: boolean): void {
    if (first) {
      this.Page.current = 1;
    } else {
      this.Page.current++;
    }
    let maxCount = this.maxCount;
    let size = maxCount < 0 ? this.Page.size : maxCount;
    if (this.recommend) {
      this.loading = true;
      this.$api.goodApi.commodity.findRecommend(
        { page: this.Page.current, size },
        (data) => {
          if (this.Page.current === 1) {
            this.mallRecommendList = data.data.records! as Array<CommodityDtos>;
          } else {
            this.mallRecommendList = this.mallRecommendList.concat(
              data.data.records! as Array<CommodityDtos>
            );
          }
          this.loading = false;
          this.List.finished = data.data.current! >= data.data.pages!;
        }
      );
    } else {
      this.loading = true;
      this.$emit("loading-change", true);
      this.$api.goodApi.commodity.searchCommodities(
        { keyword: this.searchKeyword, page: this.Page.current, size },
        (data) => {
          if (this.Page.current === 1) {
            this.mallRecommendList = data.data
              .records! as Array<good.CommodityDto>;
          } else {
            this.mallRecommendList = this.mallRecommendList.concat(
              data.data.records! as Array<good.CommodityDto>
            );
          }
          this.loading = false;
          this.$emit("loading-change", false);
          this.$emit("complete", this.mallRecommendList);
          this.List.finished = data.data.current! >= data.data.pages!;
        }
      );
    }
  }

  goGoodDetail(): void {
    this.$router.push("/mall/details");
  }
}
