































import { Mixin } from "@/core/mixins/mixin";
import { Row, Image, List, Icon, Empty, Loading } from "vant";
import { Component, Mixins, Prop } from "vue-property-decorator";
import showProList from "@/components/showProList.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [List.name]: List,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Loading.name]: Loading,
    showProList,
  },
})
export default class ShowResult extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean })
  value!: boolean;
  @Prop({ default: "暂无相关搜索结果", type: String })
  placeholder!: string;
  @Prop({ default: [], type: Array })
  shows!: Array<good.RecommendProductDto>;
  @Prop({ default: false, type: Boolean })
  finished!: boolean;
  @Prop({ default: "", type: String })
  keyword!: string;
}
