

































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Icon, List, Image, Divider, Empty } from "vant";
import YuboyaProItem from "@/components/Yuboya/YuboyaProItem.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Icon.name]: Icon,
    [List.name]: List,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    YuboyaProItem,
  },
})
export default class CategoryList extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean })
  value!: boolean;
  @Prop({ default: false, type: Boolean })
  finished!: boolean;
  @Prop({
    type: Array,
    default: () => {
      return [""];
    },
  })
  yuboyaList!: Array<good.RecommendProductDto>;
  @Prop({ default: "", type: String })
  keyWord!: string;
  scrollTop = -1;
}
